import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';




import { Loading } from './Loading';
import { Menu } from './Menu';
import { Header } from './Header';
import { API_URL } from '../utils/config'; // Importa la variable global
import { Sidebar } from './Sidebar';
import { Compartir } from './Compartir';
import { Footer } from './Footer';
//import { Meta } from '../utils/MetaUtils';



export const Fixture = () => {



	//const { config } = useMiContexto();
	const navigate = useNavigate();
	const [contadorLoading, setContadorLoading] = useState(0);



	const { idTorneo } = useParams();
	//const [dataHeader, setDataHeader] = useState({nombreCampeonato:"", nombreTorneo:""});
	const [listaEquipos, setListaEquipos] = useState(null);
	const [listaFixture, setListaFixture] = useState(null);
	const [listaFixtureFecha, setListaFixtureFecha] = useState([]);
	//const [meta, setMeta] = useState({});
	/*
	const [ogData, setOgData] = useState({
		title: '',
		description: '',
		url: ''
		// ... otras propiedades OG ...
	});
	*/
	//const [metaCargar, setMetaCargar] = useState(true);


	// META / TITULO
/*
	useEffect(() => {
		if (idTorneo !== undefined) {
			fetch(API_URL + 'Torneo/GetData?idTorneo=' + idTorneo)
				.then((response) => {
					switch (response.status) {
						case 200: response.json().then((r) => {
							setDataHeader(r);
						}); break;
						default: break;
					}
				})
				.catch((error) => { })
				.finally(() => { });
		}
	}, [idTorneo]);
*/



	useEffect(() => {
		if (listaEquipos !== null) {
			fetch(API_URL + 'Torneo/GetFixture?IdTorneo=' + idTorneo)
				.then((response) => {
					switch (response.status) {
						case 200: response.json().then((fixture) => {
							fixture.forEach(fixture => {
								const equipoLocal = listaEquipos.filter(e => e.IdEquipo === fixture.IdLocal)[0];
								const equipoVisitante = listaEquipos.filter(e => e.IdEquipo === fixture.IdVisitante)[0];
								fixture.NombreEquipoLocal = equipoLocal.Nombre;
								fixture.NombreEquipoVisitante = equipoVisitante.Nombre;
							})
							setListaFixture(fixture)
							const listaF = fixture.filter((element, index, self) => {
								return index === self.findIndex(e =>
									e.Id === element.Id
								);
							});
							listaF.forEach(fecha => {
								let equipoLibre = listaEquipos.filter(e => e.IdEquipo === fecha.IdEquipoLibre)[0];
								fecha.NombreEquipoLibre = equipoLibre.Nombre;
							});
							setListaFixtureFecha(listaF);


						}); break;
						default: break;
					}
				})
				.catch((error) => {	/**NotificationManager.warning('Hubo un error en la consulta', 'E R R O R', 3000);*/ })
				.finally(() => { setContadorLoading(-1) });
		}
	}, [listaEquipos, idTorneo])



	useEffect(() => {

		// document.querySelector('meta[property="og:url"]').setAttribute('content', window.location.href);
		// Meta({ url:window.location.href,  idTorneo:idTorneo, pagina:'fixture'});
		setContadorLoading(1);
		if (listaEquipos === null) {
			fetch(API_URL + 'Torneo/GetEquipos?IdTorneo=' + idTorneo)
				.then((response) => {
					switch (response.status) {
						case 200: response.json().then((equipos) => {
							setListaEquipos(equipos)
						}); break;
						default: break;
					}
				})
				.catch((error) => {	/**NotificationManager.warning('Hubo un error en la consulta', 'E R R O R', 3000);*/ })
				.finally(() => { });
		}

	}, [idTorneo, listaEquipos]);


	const sidebar = { actual: "fixture", idTorneo };



	return (
		<>
			<Menu idTorneo={idTorneo} seccion="fixture" />
			<Header idTorneo={idTorneo}/>
			<div className="container">
				<div className="row">
					<main className="col-md-9">
						<section className="fixture">
							<div className="panel-box my-5">
								<div className="titles">
									<h4>Fixture</h4>
								</div>
								<div className="fixture-list table-responsive">
									<div className="accordion" id="accordionFixture">
										{
											listaFixture !== null
												?
												listaFixtureFecha.map((fecha, index) => {
													return (
														<div className="accordion-item" key={index}>
															<h2 className="accordion-header" id={'heading_' + index}>
																<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={'#collapse_' + index} aria-expanded="false" aria-controls={'collapse_' + index}>
																	Fecha: {fecha.Fecha} | Libre: {fecha.NombreEquipoLibre}
																</button>
															</h2>
															<div id={'collapse_' + index} className="accordion-collapse collapse" aria-labelledby={'heading_' + index} data-bs-parent="#accordionFixture" >
																<div className="accordion-body">
																	<table className="table-striped table-hover">
																		<thead>
																			<tr>
																				<th className="text-center col-1">#</th>
																				<th className="col-4">Local</th>
																				<th className="col-4">Visitante</th>
																				<th className="col-2">Datos</th>
																			</tr>
																		</thead>
																		<tbody>
																			{listaFixture.map((fixture, index) => {
																				if (fixture.Id === fecha.Id) {
																					return (
																						<tr
																							onClick={() => navigate('/partido/' + fixture.IdPartido + '/' + idTorneo)}
																							key={index}>
																							<td className="text-center">{fixture.Partido}</td>
																							<td className="text-truncate">{fixture.NombreEquipoLocal}</td>
																							<td className="text-truncate">{fixture.NombreEquipoVisitante}</td>
																							<td className="text-truncate">{fixture.Dia} {fixture.Hora}</td>
																						</tr>
																					)
																				} else {
																					return null;
																				}
																			})}
																		</tbody>
																	</table>
																	<div className="row mt-5"></div>
																</div>
															</div>
														</div>
													)
												})
												:
												<table className='sk'>
													<tbody>
														<tr ><td className='line'></td></tr>
														<tr ><td className='line'></td></tr>
														<tr ><td className='line'></td></tr>
														<tr ><td className='line'></td></tr>
													</tbody>
												</table>
										}
									</div>
								</div>
							</div>
						</section>
					</main>
					<aside className="col-md-3">
						<Sidebar data={sidebar} />
						<Compartir seccion="fixture" idTorneo={idTorneo} />
					</aside>
				</div>
			</div>
			<Loading contador={contadorLoading} />
			<Footer/>
		</>
	)
}
